<template>
  <header>
    <div class="container">
      <router-link to="/">
        <img src="/skroutz-last-mile-logo.svg" alt="skroutz last mile logo" />
      </router-link>
      <div class="merchantName">
        <h1 v-if="!!merchantName === true">Welcome! {{ merchantName }}</h1>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    merchantName: String,
  },
};
</script>

<style scoped>
header img {
  width: 135px;
}
header .merchantName {
  text-align: center;
  justify-content: center;
}
header .container {
  width: 94%;
}
@media (min-width: 961px) {
  header .container {
    max-width: unset;
  }
}
</style>
