<template>
  <div class="loader">
    <div class="svg-wrapper">
      <loader-svg />
    </div>
    <div class="road">
      <span></span>
    </div>
    <h3>Loading...</h3>
  </div>
</template>

<script>
import LoaderSvg from "./LoaderSvg.vue";

export default {
  name: "Loader",
  components: { LoaderSvg },
};
</script>

<style>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.svg-wrapper {
  position: absolute;
  display: block;
  width: 200px;
  height: 150px;
  z-index: 10;
  animation: slide-scooter 3s ease-out;
  animation-iteration-count: infinite;
}
.road {
  display: flex;
  align-items: flex-end;
  width: 300px;
  height: 30px;
  position: relative;
  padding: 8px 4px;
  border-top: 2px solid #c5b7b7;
  border-bottom: 2px solid #c5b7b7;
}
.road > span {
  width: 100%;
  height: 1px;
  border-bottom: 3px dashed #ded5d5;
}
.loader > h3 {
  position: absolute;
  color: #c5b7b7;
  margin-top: 71px;
  font-size: 1.5em;
}

@keyframes slide-scooter {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(150px);
  }
}
</style>
