<template>
  <svg viewBox="0 0 300 150">
    <title>graphic scooter</title>
    <g id="Group" transform="translate(38.000000, 0.000000)">
      <polyline
        id="Fill-3"
        fill="#F8F8F8"
        points="66.5798824 91.2573578 64.3566275 94.858995 63.6246127 95.888098 67.5541176 98.0105391 70.2087304 93.6407548 66.5798824 91.2573578"
      ></polyline>
      <path
        d="M61.62975,52.9624166 C61.62975,52.9624166 85.301348,60.3887303 88.5648578,64.3481225 C91.8283676,68.3070686 70.5156324,94.3727695 70.5156324,94.3727695 L65.6404412,91.452294 C65.6404412,91.452294 72.5805294,74.3518774 78.6106176,71.3542303 C79.8132451,69.2884411 67.4925588,67.8315489 57.0953627,67.1829509 C46.6977206,66.5347989 48.6305784,60.3360931 51.9877647,52.731794 C58.0758431,50.1271421 61.62975,52.9624166 61.62975,52.9624166"
        id="Fill-4"
        fill="#E8E8E8"
      ></path>
      <path
        d="M61.7635735,9.72314208 C65.3848382,14.4216862 68.3244951,16.6837499 68.3931912,16.8893921 C65.5146471,19.9655489 64.8709559,24.7408186 64.8709559,24.7408186 L58.911348,17.8515833 C58.911348,17.8515833 61.4174167,12.6949166 61.7635735,9.72314208 Z"
        id="Fill-5"
        fill="#F8F8F8"
      ></path>
      <path
        d="M23.5016422,79.5232646 L19.5703529,79.5179091 C18.5002108,79.5165735 17.4938578,80.0246568 16.8595343,80.8860342 L13.5416029,85.3905342 C12.7194804,86.5070686 12.6672892,88.0134754 13.4100098,89.1844313 L15.0462255,91.7636568 L23.5016422,79.5232646"
        id="Fill-6"
        fill="#DCDCDC"
      ></path>
      <path
        d="M13.206152,86.930397 L16.5236373,82.4254509 C17.1579608,81.5640735 18.1647598,81.0564362 19.2344559,81.0577744 L22.4390833,81.0617891 L23.5016422,79.5232646 L19.5703529,79.5179091 C18.5002108,79.5165735 17.4938578,80.0246568 16.8595343,80.8860342 L13.5416029,85.3905342 C13.0919559,86.0012156 12.8809608,86.7278774 12.8961275,87.4527548 C12.984451,87.2720931 13.0839265,87.0963382 13.206152,86.930397"
        id="Fill-7"
        fill="#DCDCDC"
      ></path>
      <path
        d="M129.860582,107.751108 C129.849863,115.901407 123.234074,122.499799 115.084221,122.488661 C106.933922,122.478387 100.335529,115.862598 100.346667,107.712299 C100.356941,99.562446 106.97273,92.9640538 115.123029,92.9743017 C123.272882,92.9854656 129.871275,99.6012548 129.860582,107.751108"
        id="Fill-8"
        fill="#BBBBBB"
      ></path>
      <polygon
        id="Fill-12"
        fill="#E1E1DC"
        points="113.7045 108.825265 107.595902 92.3118872 110.854505 91.1065833 116.963995 107.619069"
      ></polygon>
      <path
        d="M122.658194,107.74174 C122.652833,111.913912 119.266206,115.29251 115.093588,115.286718 C110.920971,115.281358 107.542819,111.894284 107.54861,107.721667 C107.553971,103.549049 110.941044,100.171343 115.113216,100.17669 C119.286279,100.182495 122.663985,103.569122 122.658194,107.74174"
        id="Fill-11"
        fill="#E8E8E8"
      ></path>
      <path
        d="M117.380635,107.734603 C117.378848,108.992544 116.358221,110.010941 115.100725,110.009159 C113.842784,110.007372 112.824387,108.986299 112.826169,107.728804 C112.827956,106.470863 113.848583,105.452912 115.106525,105.454694 C116.36402,105.45648 117.382417,106.477108 117.380635,107.734603"
        id="Fill-13"
        fill="#FFFFFF"
      ></path>
      <path
        d="M46.9876857,107.641819 C46.9774118,115.792118 40.3616225,122.39051 32.2113235,122.379817 C24.0610245,122.369098 17.4630784,115.753309 17.4733263,107.60301 C17.4844902,99.4531568 24.1002794,92.8547646 32.2501324,92.8654575 C40.4004314,92.8761764 46.9988235,99.4919656 46.9876857,107.641819"
        id="Fill-15"
        fill="#BBBBBB"
      ></path>
      <path
        d="M39.7857427,107.632897 C39.7803824,111.805069 36.3933088,115.182774 32.2206912,115.177873 C28.0480735,115.172069 24.6703676,111.784995 24.6757142,107.612823 C24.6815196,103.440206 28.0681471,100.062054 32.2407647,100.067845 C36.4129363,100.07276 39.7915343,103.459833 39.7857427,107.632897"
        id="Fill-18"
        fill="#E8E8E8"
      ></path>
      <path
        d="M34.5081837,107.62576 C34.5063971,108.883255 33.4853235,109.901652 32.2278284,109.89987 C30.9698873,109.898083 29.9519363,108.877456 29.9537182,107.619515 C29.9555049,106.36202 30.9761324,105.343622 32.2336275,105.345404 C33.4915686,105.347191 34.5099657,106.367819 34.5081837,107.62576"
        id="Fill-19"
        fill="#FFFFFF"
      ></path>
      <path
        d="M103.399642,97.457848 C103.399642,97.457848 115.485691,91.5196519 128.314461,94.8215244 C128.220338,88.1481911 123.098912,79.1070735 107.797529,82.7827597 C92.495701,86.458446 103.399642,97.457848 103.399642,97.457848"
        id="Fill-21"
        fill="#DCDCDC"
      ></path>
      <path
        d="M99.8488578,37.8314362 C99.8488578,37.8314362 103.104338,43.5764803 100.119181,47.7803235 C97.5524461,47.1451078 99.8488578,37.8314362 99.8488578,37.8314362"
        id="Fill-23"
        fill="#FFFFFF"
      ></path>
      <path
        d="M107.797529,82.7827597 L112.521946,81.4543382 C112.521946,81.4543382 108.124505,68.2049166 96.5496618,48.3758382 C95.5223431,48.8192401 94.3625392,49.1720882 94.3625392,49.1720882 L107.797529,82.7827597"
        id="Fill-24"
        fill="#DCDCDC"
      ></path>
      <path
        d="M94.3121324,87.0048921 C93.1389461,91.5241127 98.8433971,90.2996274 98.8433971,90.2996274 C98.8433971,90.2996274 105.377108,75.5933136 91.7761765,48.3695931 C91.1436373,47.9922107 90.0806324,49.3424901 90.0806324,49.3424901 C90.0806324,49.3424901 100.288245,73.7309362 94.3121324,87.0048921 Z"
        id="Fill-25"
        fill="#DCDCDC"
      ></path>
      <path
        d="M38.2757598,70.7819035 C33.0744853,70.7756666 16.6387255,77.8718823 11.5449559,99.903696 C25.2333186,99.9215391 39.3842647,99.9402744 43.3534706,99.9451813 C47.3231225,99.9505342 48.1033137,107.0695 48.1033137,107.0695 C63.0241912,107.089127 83.2632157,107.115892 87.917152,107.12214 C92.5710882,107.128382 100.807926,89.2071813 96.4399265,80.0304558 C94.5106373,89.609544 90.260402,94.5320195 85.4677353,95.8943431 C81.7822353,96.9421813 70.9746471,96.8471666 66.1779657,96.747245 C64.7549755,96.7178038 63.54075,95.7199264 63.2289412,94.3303921 C62.6325343,91.6722107 61.9098873,87.2230244 62.6245049,83.4077156 C63.7276569,77.523495 70.1623382,76.710294 70.7177059,70.8251813 C70.7177059,70.8251813 43.4774804,70.7890489 38.2757598,70.7819035 Z"
        id="Fill-26"
        fill="#DCDCDC"
      ></path>
      <path
        d="M10.8588873,101.134426 L49.6654804,101.185725 C49.6654804,101.185725 55.3284461,89.0787107 50.8199314,82.1943823 C46.3118627,75.3100538 27.7273431,77.4414166 19.8130196,84.4122842 C11.89825,91.3827058 10.8588873,101.134426 10.8588873,101.134426"
        id="Fill-27"
        fill="#DCDCDC"
      ></path>
      <path
        d="M48.3905882,101.183941 C49.5147059,98.4691078 52.7880294,89.4369117 49.1502598,83.9247205 C45.0539216,77.7184313 28.8380784,79.2217156 20.7025,85.0430391 C29.313598,80.896294 42.2645931,80.3984705 45.8622157,85.849995 C48.8705686,90.4075784 47.1536127,97.3708627 45.8332206,101.180819 L48.3905882,101.183941"
        id="Fill-28"
        fill="#DCDCDC"
      ></path>
      <path
        d="M79.4358627,102.970485 C86.5195882,102.979407 100.490319,92.0130146 97.6920686,75.1722156 C94.8942647,58.3318627 90.5927304,50.3185097 90.5927304,50.3185097 L95.5223431,48.8192401 C95.5223431,48.8192401 104.943074,67.1740293 106.989235,72.6523186 C109.035397,78.1301617 112.095941,92.8494117 100.995279,102.998588 C96.4729363,106.791147 92.9801422,108.326103 87.8471176,108.319896 C82.7136471,108.312721 48.4244902,108.268113 48.4244902,108.268113 L46.9939167,102.927662 C46.9939167,102.927662 72.3521373,102.960671 79.4358627,102.970485 Z"
        id="Fill-29"
        fill="#DCDCDC"
      ></path>
      <path
        d="M106.989235,72.6523186 C104.943074,67.1740293 95.5223431,48.8192401 95.5223431,48.8192401 L93.6827157,49.3786225 C95.9474559,54.0945636 102.478936,67.894446 103.909064,73.1273921 C105.450265,78.7684999 108.725373,93.8887744 97.6242647,104.037951 C95.4670294,105.846799 93.5439853,107.136858 91.5656275,107.996451 C94.8657157,107.372387 97.6492451,105.804421 100.995279,102.998588 C112.095941,92.8494117 109.035397,78.1301617 106.989235,72.6523186"
        id="Fill-30"
        fill="#DCDCDC"
      ></path>
      <path
        d="M40.4240735,63.8039068 C40.4240735,63.8039068 35.2812353,70.7783431 40.671201,70.7854803 C46.0611667,70.7926176 69.2460931,70.8229509 70.7177059,70.8251817 C72.1893186,70.8269656 74.1980098,65.1831813 71.4782696,64.9744166 C68.7576373,64.7652058 60.3379069,65.7804803 53.9750441,64.3351862 C47.6117353,62.889446 42.377451,61.5480882 40.4240735,63.8039068"
        id="Fill-32"
        fill="#DCDCDC"
      ></path>
      <path
        d="M90.1818922,50.7284558 C90.1818922,50.7284558 96.7548578,48.5814803 97.5765343,48.3771764 C98.3977647,48.1728725 100.143716,47.9699068 100.143716,47.9699068 C100.143716,47.9699068 99.0758039,40.0636127 100.157098,37.8573087 C97.8499804,35.6474362 94.6150196,36.3616078 91.2230392,39.5394705 C87.8310588,42.7177793 86.6498431,43.2807303 86.905,44.1537058 C87.1606029,45.0271274 90.1818922,50.7284558 90.1818922,50.7284558"
        id="Fill-33"
        fill="#E8E8E8"
      ></path>
      <path
        d="M90.7225392,42.7936127 L90.4896863,42.3997254 L95.1494216,39.6456372 C95.4946863,39.4417793 95.7382451,39.0996372 95.8176471,38.7075342 L97.6505833,29.7065636 L98.0993382,29.7980097 L96.2659559,38.7985342 C96.1602353,39.3177695 95.8381667,39.769647 95.3822745,40.0395244 L90.7225392,42.7936127"
        id="Fill-34"
        fill="#DCDCDC"
      ></path>
      <path
        d="M93.2696491,44.4829117 C93.2674167,46.0495391 92.0710343,47.3177401 90.5967451,47.3155127 C89.1224559,47.3137254 87.9291961,46.0424019 87.9314233,44.4762205 C87.9332108,42.9095931 89.1300392,41.6413921 90.6043284,41.6436195 C92.0781716,41.6454068 93.2714314,42.9167303 93.2696491,44.4829117"
        id="Fill-35"
        fill="#DCDCDC"
      ></path>
      <path
        d="M84.3422789,42.0464313 C84.3422789,42.0464313 87.3296667,41.9469558 88.4149755,40.9562156 C89.4998382,39.9650293 90.693098,39.9958087 91.7855441,40.9133921 C92.8779902,41.8314215 93.7326765,43.6612352 93.2607255,44.6814166 C92.7887745,45.701598 91.6593039,46.1449999 90.8724216,45.8702156 C91.1793235,46.589294 89.4797647,46.6967989 88.6362304,46.3952499 C87.7918039,46.0932548 85.8080931,45.5896323 85.0207647,45.7600342 C84.3373725,44.8692156 84.3422789,42.0464313 84.3422789,42.0464313"
        id="Fill-36"
        fill="#F8F8F8"
      ></path>
      <path
        d="M89.0666961,48.5970931 C89.6791618,49.779647 90.1818922,50.7284558 90.1818922,50.7284558 C90.1818922,50.7284558 96.7548578,48.5814803 97.5765343,48.3771764 C98.3977647,48.1728725 100.143716,47.9699068 100.143716,47.9699068 C100.143716,47.9699068 99.9483333,46.5201519 99.8096029,44.6903382 C97.2094118,47.4328284 93.0947843,48.3597793 89.0666961,48.5970931"
        id="Fill-37"
        fill="#E8E8E8"
      ></path>
      <path
        d="M91.7815314,43.9034558 C91.7806373,44.7255784 91.1133039,45.3911274 90.2916275,45.3902361 C89.4690588,45.3893431 88.8035098,44.7215636 88.804846,43.8998872 C88.8057402,43.0773186 89.4730735,42.4117695 90.2951961,42.4131058 C91.1173186,42.4139999 91.7828676,43.0813333 91.7815314,43.9034558"
        id="Fill-38"
        fill="#DCDCDC"
      ></path>
      <path
        d="M98.6065294,28.8130686 C98.2095196,31.1482891 97.1433922,32.9147597 96.2244706,32.7586323 C95.3064412,32.6025048 94.8835588,30.5831078 95.2801225,28.2478872 C95.6775784,25.9126666 96.7437059,24.146196 97.6621814,24.3023235 C98.5806569,24.4584509 99.0035392,26.477848 98.6065294,28.8130686"
        id="Fill-39"
        fill="#DCDCDC"
      ></path>
      <path
        d="M97.7326618,28.6645244 C97.4163922,30.5251176 96.6870539,31.9525686 96.1031373,31.8530931 C95.5192206,31.7540636 95.3024265,30.1655784 95.6186961,28.3054313 C95.9349657,26.4452842 96.66475,25.0178333 97.2482206,25.1168627 C97.8321373,25.2158921 98.0489314,26.8043774 97.7326618,28.6645244"
        id="Fill-40"
        fill="#F2F1EE"
      ></path>
      <polyline
        id="Fill-41"
        fill="#F8F8F8"
        points="71.8012304 92.3859362 70.1534167 96.2846617 69.5877892 97.4136862 73.7943088 98.9116176 75.7512549 94.187647 71.8012304 92.3859362"
      ></polyline>
      <path
        d="M62.0115931,54.1298038 C62.0115931,54.1298038 86.3719363,58.8265636 90.0636814,62.3902842 C93.7545343,65.9540048 75.9760784,95.0606225 75.9760784,95.0606225 L70.8015686,92.7115735 C70.8015686,92.7115735 75.3158824,74.1413284 80.96725,70.4794705 C81.928549,68.2901176 69.5217696,68.2388186 59.1178824,68.7727744 C48.7139951,69.3067303 45.3014951,62.0472499 47.7758922,54.1110686 C53.5294118,50.8337303 62.0115931,54.1298038 62.0115931,54.1298038"
        id="Fill-42"
        fill="#E8E8E8"
      ></path>
      <path
        d="M75.9760784,95.0606225 C75.9760784,95.0606225 93.7545343,65.9540048 90.0636814,62.3902842 C87.3350196,59.755745 73.312098,56.502495 66.1842108,54.9849362 C64.0568627,55.1013627 61.2028529,55.7932303 58.7565588,58.2332793 C64.4043578,57.0600931 82.3652598,61.1898872 85.8544853,62.1181764 C89.3441569,63.0473578 89.5984216,64.8441617 88.4649363,67.8712499 C87.3702598,70.7961862 77.6818824,90.4517401 75.1205,94.6720882 L75.9760784,95.0606225"
        id="Fill-43"
        fill="#E8E8E8"
      ></path>
      <path
        d="M47.4667598,55.9083186 C47.4667598,55.9083186 45.0704265,29.7422499 58.911348,17.8515833 C62.3314314,19.3615587 65.0636618,23.3352254 66.4282157,26.7588774 C67.7923235,30.1829754 66.805598,51.9462499 66.3925294,53.5882646 C66.2850245,54.0164999 65.2371863,54.5178921 63.6491471,54.9862744 C59.1486618,56.3142499 51.511799,57.5324901 47.4667598,55.9083186"
        id="Fill-44"
        fill="#E8E8E8"
      ></path>
      <path
        d="M58.7587892,56.5832352 C58.7587892,56.5832352 64.0314363,54.3559656 64.7982451,43.8682156 C65.5650539,33.3804656 65.434799,28.4892156 65.434799,28.4892156 C65.434799,28.4892156 67.8882304,36.2920195 68.0184853,41.6137352 C68.1482941,46.9350048 67.3194804,52.8201176 66.2890392,55.3516127 C65.2590441,57.8822156 58.7587892,56.5832352 58.7587892,56.5832352"
        id="Fill-45"
        fill="#E8E8E8"
      ></path>
      <path
        d="M59.9872892,30.2931568 C60.7500833,34.9002548 63.3003137,46.0334803 65.3732402,48.1800097 C68.7995686,51.7272254 85.2928725,47.0607989 85.2928725,47.0607989 L84.7526716,42.0526764 C79.619201,41.9772891 72.6362892,41.9612303 71.5416127,41.4808038 C70.4473824,41.0003774 68.2549069,30.1713774 67.6419951,27.9119901 C67.0290833,25.6526029 65.3032059,24.276897 62.4286765,24.4102744 C59.553701,24.5432058 59.7174118,28.662294 59.9872892,30.2931568"
        id="Fill-46"
        fill="#E8E8E8"
      ></path>
      <path
        d="M48.8000882,56.758544 C48.8000882,56.758544 48.4093235,41.7007205 51.0866863,35.7906274 C55.6134902,28.241196 59.2115588,24.760446 61.4196471,24.0725931 C63.6277353,23.3851862 65.4771765,24.7131617 65.4771765,24.7131617 C65.4771765,24.7131617 64.9369755,23.3695735 64.325848,22.7954705 C63.7147206,22.2218136 61.1145294,21.5852597 58.802951,23.2763431 C56.4904804,24.9669803 50.3640392,32.3366421 49.1997745,35.1496127 C47.5657892,39.0974068 47.3155392,42.948848 47.2410441,56.2937303 C47.5938922,57.0119166 48.8000882,56.758544 48.8000882,56.758544"
        id="Fill-47"
        fill="#E8E8E8"
      ></path>
      <path
        d="M70.1534167,96.2846617 C70.1534167,96.2846617 71.0081029,98.470446 74.0186863,98.3700784 C76.0006127,100.142348 81.0818922,100.200784 82.861299,100.134319 C84.641152,100.068299 84.0224412,102.189402 81.4891618,102.870118 C78.9558824,103.551279 68.3481373,102.955319 67.2530147,102.95398 C66.1578922,102.952642 67.8074902,97.5479558 70.1534167,96.2846617"
        id="Fill-48"
        fill="#F1F1F1"
      ></path>
      <path
        d="M73.8616667,9.20524502 C74.8394706,7.15328424 72.1723676,5.37923032 72.1723676,5.37923032 C72.1723676,5.37923032 72.1183922,5.45372541 72.0278382,5.58130385 C70.8189657,3.58733326 68.9204559,2.29504404 66.777049,2.29191353 C63.1165294,2.28746071 60.1438627,6.02916659 60.137598,10.6505391 C60.1313725,15.2710195 63.0977941,18.8735489 66.7587598,18.8784558 C66.8586814,18.8784558 66.9528039,19.0131715 67.0522794,19.0073725 C68.5020343,19.9557352 70.4951127,19.101495 70.7435784,18.4408529 C71.0527108,17.6200686 71.2458627,17.1325048 71.747701,17.133397 C72.2495392,17.1342891 72.1192843,16.4923823 71.7490392,16.172098 C72.0202549,16.263098 72.5689314,16.2104607 72.4792696,16.0177548 C72.3900539,15.825495 72.5698235,15.4151029 72.7076618,14.3659264 C74.0235931,14.9355686 74.2403873,13.5946568 73.8563137,13.2351176 C74.0713235,12.5570784 72.8834167,11.2572058 73.8616667,9.20524502 Z"
        id="Fill-49"
        fill="#F8F8F8"
      ></path>
      <path
        d="M59.3279853,14.3195342 L59.485451,16.6039019 C59.485451,16.6039019 63.352951,17.7641519 64.485098,15.4681862 C65.6172451,13.1726666 59.3279853,14.3195342 59.3279853,14.3195342"
        id="Fill-50"
        fill="#F1F1F1"
      ></path>
      <g id="Group-3" transform="translate(57.326411, 0.000000)" fill="#DCDCDC">
        <path
          d="M11.60029,8.35903424 C11.60029,8.35903424 17.3564861,9.07275973 19.1010988,9.74232345 C20.0820253,5.38056855 17.0580596,1.72495581 12.2859125,0.384044042 C7.51421156,-0.956867723 2.49761352,1.32482345 0.849353717,5.78828424 C-0.799352165,10.251745 0.275696854,12.2599901 1.35163803,13.9336764 C2.22327529,15.2888627 3.58069195,16.2751421 7.44016254,16.0463038 C9.98147136,15.8955293 9.99396156,12.2457156 10.3472557,10.8914215 C10.7085792,9.50590188 10.8285743,8.17346561 11.60029,8.35903424"
          id="Fill-54"
        ></path>
        <path
          d="M12.2060645,0.377798944 C9.24098117,-0.451014782 6.18445176,0.119519532 3.89963803,1.73967639 C5.7450645,1.09999992 7.82646646,1.00409306 9.86593705,1.5741813 C13.9885939,2.72640188 16.8064714,5.60717639 16.8720449,9.18115679 C17.7615253,9.35557345 18.550638,9.54292639 19.0328488,9.72760287 C20.0084223,5.36406365 16.9799959,1.71246561 12.2060645,0.377798944"
          id="Fill-55"
        ></path>
        <path
          d="M9.76646156,10.2785097 C9.41361352,11.6328038 9.40023117,15.2830636 6.85936842,15.4333921 C4.04416744,15.6006715 2.56274097,15.1193529 1.62776058,14.3217646 C2.51724097,15.4704166 3.96119685,16.2523921 7.44016254,16.0463038 C9.98147136,15.8955293 9.99396156,12.2457156 10.3472557,10.8914215 C10.7085792,9.50590188 10.8285743,8.17346561 11.60029,8.35903424 C11.60029,8.35903424 17.3564861,9.07275973 19.1010988,9.74232345 C19.1613194,9.47333816 19.2063733,9.20747541 19.2367067,8.94428914 C17.4287508,8.28230875 11.0194959,7.74656855 11.0194959,7.74656855 C10.2473341,7.56055385 10.127339,8.89299012 9.76646156,10.2785097 Z"
          id="Fill-56"
        ></path>
        <path
          d="M15.188991,8.69047051 C15.4084616,8.72481855 18.436442,9.05402443 19.5293341,10.5613235 C20.4125694,9.80522051 19.1898684,8.54192639 19.1898684,8.54192639 L15.188991,8.69047051"
          id="Fill-53"
        ></path>
        <path
          d="M19.5293341,10.5613235 C19.2830988,10.2218578 18.9382802,9.94172051 18.5497459,9.71154404 C18.2009125,9.50545581 17.8172851,9.33906855 17.4367802,9.20524502 C17.9560155,9.29178424 18.481942,9.38501463 18.9779812,9.48181365 C18.9779812,9.48225973 18.9779812,9.48315188 18.9779812,9.48359796 C19.2746233,9.54158816 19.5601135,9.60136267 19.8264223,9.66158326 C19.8888733,9.97339208 19.8366821,10.2981372 19.5293341,10.5613235"
          id="Fill-61"
        ></path>
        <path
          d="M8.49201548,15.7197744 C8.43848607,15.3865538 8.41216744,15.0354901 8.41662823,14.6656911 C9.43190274,13.4858136 9.50818215,11.2688038 9.76646156,10.2785097 C10.104589,8.98042149 10.2312753,7.72917149 10.8807655,7.72917149 C10.9249272,7.72917149 10.9708733,7.73497051 11.0194959,7.74656855 C11.0194959,7.74656855 14.2883586,8.01956855 16.8153929,8.41702443 C17.7842753,8.56958326 18.6438684,8.73998522 19.1693488,8.9202009 C19.1925449,8.92823032 19.2148488,8.93625973 19.2367067,8.94428914 C19.2094959,9.17803424 19.1711331,9.41445581 19.1202802,9.65310777 C19.1140351,9.68299502 19.10779,9.71288228 19.1010988,9.74232345 C19.0796871,9.73429404 19.0578292,9.72626463 19.0350792,9.71778914 C18.5354714,9.53578914 17.7494812,9.35155875 16.8720449,9.17937247 C16.3760057,9.08257345 15.8500792,8.98934306 15.3308439,8.90280385 C13.4247508,8.58564208 11.60029,8.35903424 11.60029,8.35903424 C11.5516674,8.3474362 11.5052753,8.34163718 11.4615596,8.34163718 C10.8125155,8.34163718 10.6858292,9.59333326 10.3472557,10.8914215 C10.2861429,11.1260587 10.23529,11.4298382 10.1795302,11.7728725 C10.1581184,11.9040195 10.1358145,12.0414117 10.1121723,12.1823725 C9.95559882,13.1195833 9.73122136,14.232995 9.19191254,15.0243382 C9.11072627,15.1434411 9.02195666,15.2554068 8.92560372,15.3584509 C8.79624097,15.4962891 8.65215764,15.6180686 8.49201548,15.7197744"
          id="Fill-64"
        ></path>
      </g>
      <path
        d="M70.9541275,9.89866832 C70.9541275,9.89866832 72.6831275,9.3531144 73.2808725,9.90179087 C73.3495686,10.090036 73.1501716,10.3295801 72.8258725,10.2604379 C72.5020196,10.1917419 71.4590882,9.94193793 70.9541275,9.89866832"
        id="Fill-51"
        fill="#F1F1F1"
      ></path>
      <path
        d="M50.8422353,73.5707944 L35.5836765,73.5502744 C34.4979216,73.5489362 33.4777402,72.9699264 32.9205882,72.0380686 L31.0577647,68.9235489 C30.8686275,68.6072793 30.5220245,68.4110048 30.1535637,68.4101127 L4.84530392,68.3771029 C4.27789216,68.3762107 3.81887745,67.9163038 3.81976831,67.3488921 C3.82066176,66.7819264 4.28056863,66.3229117 4.84798039,66.3238025 L30.1562402,66.3568136 C31.2419951,66.3581519 32.2621765,66.9380538 32.8197683,67.8694656 L34.682598,70.9839852 C34.8717353,71.2998087 35.2183382,71.4969754 35.586799,71.4974215 L50.8453578,71.517495 C51.4118775,71.5183872 51.8713382,71.978294 51.8704474,72.5452597 C51.8695539,73.1126715 51.4096471,73.5712401 50.8422353,73.5707944"
        id="Fill-65"
        fill="#DCDCDC"
      ></path>
      <path
        d="M32.4419461,66.3599392 L3.31347059,66.3215735 C1.80171078,66.3193431 0.57722549,65.0921813 0.57945284,63.5804215 L0.614696078,36.9223284 C0.616480392,35.4101225 1.84408824,34.1860833 3.35584804,34.1883106 L32.4843235,34.2266764 C33.9960833,34.2284607 35.2205686,35.4556225 35.2183413,36.9678284 L35.183098,63.6259215 C35.1813137,65.1381274 33.9537059,66.3621666 32.4419461,66.3599392"
        id="Fill-66"
        fill="#DCDCDC"
      ></path>
      <path
        d="M35.2183443,36.9678284 C35.2205686,35.4556225 33.9960833,34.2284607 32.4843235,34.2266764 L3.35584804,34.1883076 C1.84408824,34.1860833 0.616480392,35.4101225 0.614696078,36.9223284 L0.60845098,41.9184068 L35.2116471,41.9639068 L35.2183443,36.9678284 Z"
        id="Fill-67"
        fill="#F8F8F8"
      ></path>
      <path
        d="M35.8232206,40.6386078 L35.8281304,36.9539999 C35.8303578,35.3887107 34.563049,34.1182793 32.9977598,34.1160489 L2.84285784,34.076345 C1.27756863,34.0741176 0.00669117647,35.3414264 0.00446078431,36.9067156 L1.26783116e-14,40.5913235 L35.8232206,40.6386078"
        id="Fill-68"
        fill="#DCDCDC"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "LoaderSvg",
};
</script>

<style></style>
