<template>
  <div></div>
</template>

<script>
export default {
  watch: {
    $route() {
      this.updateMeta();
    },
  },
  mounted() {
    this.updateMeta();
  },
  methods: {
    updateMeta() {
      const currentRoute = this.$route.path;

      if (currentRoute === '/') {
        this.addIndexMeta();
      } else {
        this.addNoIndexMeta();
      }
    },
    addIndexMeta() {
      this.removeNoIndexMeta(); // Ensure no "noindex" meta tag is present

      if (!document.getElementById('index-meta')) {
        let metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'index, follow';
        metaTag.id = 'index-meta';
        document.head.appendChild(metaTag);
      }
    },
    addNoIndexMeta() {
      this.removeIndexMeta(); // Ensure no "index" meta tag is present

      if (!document.getElementById('noindex-meta')) {
        let metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex, nofollow';
        metaTag.id = 'noindex-meta';
        document.head.appendChild(metaTag);
      }
    },
    removeIndexMeta() {
      let metaTag = document.getElementById('index-meta');
      if (metaTag) {
        document.head.removeChild(metaTag);
      }
    },
    removeNoIndexMeta() {
      let metaTag = document.getElementById('noindex-meta');
      if (metaTag) {
        document.head.removeChild(metaTag);
      }
    },
  },
};
</script>
