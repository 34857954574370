<template>
  <div>
    <h2 class="shipment-header">Παρακολούθηση αποστολής</h2>

    <div v-if="possibleDelay && isStatusDelivered === false" class="delay-banner">
      Eνδέχεται να υπάρξουν καθυστερήσεις στις παραδόσεις λόγω της απεργίας της Πανελλήνιας Ναυτικής Ομοσπονδίας (ΠΝΟ)
    </div>


    <section
      class="shipment-eta"
      v-if="
        isStatusDelivered === false &&
          isLost === false &&
          isCompleteCancelled === false
      "
    >
      <div class="shipment-eta-container">
        <h2 class>Εκτιμώμενη παράδοση:</h2>
        <p class="delivery-day">
          <span v-if="trackingData.eta">
            {{
              formatDateTime(
                trackingData.eta["startTime"],
                trackingData.eta["endTime"]
              )
            }}
          </span>
          <span v-else-if="trackingData.expectedDayOfDelivery">
            {{ formatExpectedDate(trackingData.expectedDayOfDelivery) }}
          </span>
        </p>
      </div>
      <br />
      <div class="shipment-eta-container">
        <p class="order-status">
          <span v-if="trackingData.failedDeliveryText">
            {{ changeDeliveryFailText(trackingData.failedDeliveryText) }}</span
          >
        </p>
      </div>
    </section>

    <section class="shipment-state">
      <div class="status-container">
        <h2>
          {{ trackingData.statusTextGr }}
        </h2>
        <div
          v-if="isStatusDelivered && lockerReturnEnabled"
          class="return-button-container"
        >
          <button @click="handleReturnClick">
            Επιστροφή
          </button>
          <p class="return-info">Επιστροφή του δέματος σας σε Skroutz Point.</p>
        </div>
      </div>

      <progress
        :value="isStatusDelivered || isCompleteCancelled ? 4 : progressBar"
        max="4"
      ></progress>

      <div class="grid-table">
        <p>
          <span class="title-col">Αρ. αποστολής:</span>
          <span>{{ shipmentTrackingId }}</span>
        </p>
        <p v-if="userOrderCode">
          <span class="title-col">Αρ. παραγγελίας:</span>
          <a
            :href="
              'https://www.skroutz.gr/account/ecommerce/orders/' + userOrderCode
            "
            target="_blank"
            rel="nofollow"
            >{{ userOrderCode }}</a
          >
        </p>
      </div>
    </section>

    <section>
      <ul v-if="convertedTrackingDetails.length > 0" class="tracking-steps">
        <li
          v-for="detail in convertedTrackingDetails"
          :key="detail.id"
          :class="!detail.isNotDelivered ? 'delivered-step' : ''"
        >
          <article>
            <p>{{ detail.description_gr }}</p>
            <p>{{ detail.createdAt }}</p>
          </article>
        </li>
      </ul>

      <ul v-else class="tracking-steps">
        <li>
          <article>
            <p>Δημιουργία διαδρομής / Αναμονή παραλαβής</p>
          </article>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import Bugsnag from "@bugsnag/js";

export default {
  name: "MainPage",
  props: {
    trackingData: {
      type: Object,
      required: true,
    },
    shipmentTrackingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      convertedTrackingDetails: [],
      userOrderCode: "",
      isStatusDelivered:
        this.trackingData.status === "delivered" ||
        (this.trackingData.status === "at_locker" &&
          this.trackingData.isReturn === false) ||
        (this.trackingData.status === "at_locker" &&
          this.trackingData.isReturn === true &&
          (this.trackingData.internalState === "matched" ||
            this.trackingData.internalState === "not_routed"))
          ? true
          : false,
      isLost: this.trackingData.status === "lost_or_damaged" ? true : false,
      progressBar: "0.1",
      isPickedUp: false,
      isReturn: this.trackingData.isReturn,
      failedDeliveryText: this.trackingData.failedDeliveryText
        ? this.trackingData.failedDeliveryText
        : "",
      isCompleteCancelled: this.trackingData.isCompleteCancelled,
      lockerReturnEnabled: this.trackingData.lockerReturnEnabled,
      possibleDelay: this.trackingData.possibleDelay,
    };
  },
  computed: {
    adjustedTrackingDetails: function() {
      return this.trackingData["trackingDetails"].map((el, index) => {
        // el.description = this.mapTracking(
        //   el.description,
        //   this.isPickedUp,
        //   this.isReturn,
        //   el.notes
        // ); // check when is it false
        el.createdAt = this.formatDateTime(el.createdAt);

        // setting bullet class background if delivered
        if (
          index === this.trackingData["trackingDetails"].length - 1 &&
          el.latestEta &&
          this.isDateDelivered(el.latestEta)
        ) {
          el.isNotDelivered = true;
        }

        return el;
      });
    },
  },
  mounted() {
    this.convertedTrackingDetails = this.adjustedTrackingDetails;
    this.userOrderCode = this.trackingData.userOrderCode ?? null;

    if (this.trackingData.needsFallbackAddress) {
      if (window.drift && window.drift.api) {
        window.drift.api.startInteraction({
          interactionId: 314234,
          replaceActiveConversation: false,
        });
      } else {
        window.drift.on("ready", () => {
          window.drift.api.startInteraction({
            interactionId: 314234,
            replaceActiveConversation: false,
          });
        });
      }
      return;
    }

    if (
      this.trackingData.status === "attempted_delivery" &&
      this.trackingData.internalState === "failed_delivery"
    ) {
      let curDate = new Date();
      let dayOfWeek = curDate.getDay();
      var isSunday = dayOfWeek === 0; // 6 = Saturday, 0 = Sunday
      //Weekdays playbook
      if (!isSunday) {
        if (window.drift && window.drift.api) {
          window.drift.api.startInteraction({
            interactionId: 294526,
            replaceActiveConversation: false,
          });
        } else {
          window.drift.on("ready", () => {
            window.drift.api.startInteraction({
              interactionId: 294526,
              replaceActiveConversation: false,
            });
          });
        }
        //Sunday playbook
      } else if (isSunday) {
        if (window.drift && window.drift.api) {
          window.drift.api.startInteraction({
            interactionId: 296848,
            replaceActiveConversation: false,
          });
        } else {
          window.drift.on("ready", () => {
            window.drift.api.startInteraction({
              interactionId: 296848,
              replaceActiveConversation: false,
            });
          });
        }
      }
    }
  },
  methods: {
    // mapShipment(status) {
    //   switch (status) {
    //     case "awaiting_pickup":
    //       return "Αναμονή παραλαβής";
    //     case "picked_up":
    //       return "Ολοκλήρωση παραλαβής";
    //     case "assigned_to_courier_for_delivery":
    //       return "Έναρξη παράδοσης";
    //     case "attempted_delivery":
    //       return "Αποτυχία παράδοσης";
    //     case "delivered":
    //       return "Παραδόθηκε";
    //     case "at_locker":
    //       return "Παραδόθηκε σε Skroutz Point";
    //     case "recipient_rejected":
    //       return "Απόρριψη παράδοσης";
    //     case "returned_to_sender":
    //       return "Επιστροφή στο κατάστημα";
    //     default:
    //       console.error();
    //       return "";
    //   }
    // },
    // mapTracking(status, hasBeenPickedUp, isReturn, notes) {
    //   let out = "";
    //   switch (status) {
    //     case "Waiting For Pickup":
    //       this.progressBar = hasBeenPickedUp ? 2 : 1;
    //       out = hasBeenPickedUp
    //         ? "Δημιουργία επαναδρομολόγησης"
    //         : "Δημιουργία διαδρομής / Αναμονή παραλαβής";
    //       break;
    //     case "Shipment was picked up":
    //       this.progressBar = 2;
    //       out = hasBeenPickedUp
    //         ? "Παραλαβή από το κέντρο διαλογής της Skroutz"
    //         : isReturn
    //         ? "Παραλαβή απο εσάς"
    //         : "Παραλαβή από το κατάστημα";
    //       this.isPickedUp = true;
    //       break;
    //     case "Scanned":
    //       if (notes.contains("SORTER")) {
    //         this.progressBar = 2;
    //         out = hasBeenPickedUp
    //           ? "Παραλαβή από το κέντρο διαλογής της Skroutz"
    //           : "Παραλαβή από το κατάστημα";
    //         this.isPickedUp = true;
    //       }
    //       break;
    //     case "Failed to pickup":
    //       out = hasBeenPickedUp
    //         ? "Αποτυχία παραλαβής από το κέντρο διαλογής της Skroutz"
    //         : isReturn
    //         ? "Αποτυχία παραλαβής απο εσάς"
    //         : "Αποτυχία παραλαβής από το κατάστημα";
    //       break;
    //     case "Shipment was assigned to courier for_delivery":
    //       this.progressBar = 3;
    //       out = "Έναρξη παράδοσης";
    //       break;
    //     case "Shipment was delivered":
    //       this.progressBar = 4;
    //       out = "Παραδόθηκε";
    //       break;
    //     case "Shipment arrived at locker":
    //       this.progressBar = 4;
    //       out = "Παράδοση σε Skroutz Point";
    //       break;
    //     case "Shipment was not delivered. Recipient absent":
    //     case "Shipment was not delivered":
    //     case "Shipment was not delivered. Recipient rejected the delivery":
    //     case "Shipment was not delivered. Wrong recipient address":
    //       out = "Αποτυχία παράδοσης";
    //       break;
    //     case "Shipment is returning to sender":
    //     case "Shipment was returned to sender":
    //       out = "Επιστροφή στο κατάστημα";
    //       break;
    //     default:
    //       out = "";
    //   }

    //   return out;
    // },
    previewAfterCheck(responseDate, initialDate) {
      if (responseDate === "Invalid Date") {
        Bugsnag.notify(
          `Track Id: ${this.shipmentTrackingId} - Error date fortmat catched: ${initialDate}`
        );
        return "";
      }

      return responseDate;
    },
    formatDateTime(dateToDate, dateToTime = null) {
      if (!dateToDate) return "-";

      let formattedDate = new Date(dateToDate).toLocaleDateString("el-GR", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      });
      formattedDate = this.previewAfterCheck(formattedDate, dateToDate);

      if (!dateToTime) return formattedDate;

      let formattedHour = new Date(dateToTime).toLocaleTimeString("el-GR", {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      });
      formattedHour = this.previewAfterCheck(formattedHour, dateToTime);

      return `${formattedDate} - ${formattedHour}`;
    },
    formatExpectedDate(expectedDate) {
      let formattedDate = new Date(expectedDate).toLocaleDateString("el-GR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return formattedDate;
    },
    isDateDelivered(dateEta) {
      // compare last step's time from current time
      return Date.parse(new Date(dateEta)) > Date.now();
    },
    hasSlot(trackingDetails) {
      let hasSlot = false;
      for (let detail of trackingDetails) {
        if (detail.description === "Shipment arrived at locker") {
          hasSlot = true;
        }
      }
      return hasSlot;
    },
    changeDeliveryFailText(failedDeliveryText) {
      if (
        failedDeliveryText ===
        'Επίλεξε "Χρειάζεσαι βοήθεια;" > "Επαναδρομολόγηση μετά από αποτυχία παράδοσης", στο τέλος της σελίδας, ώστε να καταχωρήσεις νέα ημερομηνία ή και διεύθυνση παράδοσης που σε εξυπηρετεί.'
      ) {
        return "Επίλεξε το κουμπί chat κάτω δεξιά για επαναδρομολόγηση, ώστε να καταχωρήσεις νέα ημερομηνία ή και διεύθυνση παράδοσης που σε εξυπηρετεί.";
      } else if (
        failedDeliveryText ===
        "Η επιστροφή του δέματος στη θυρίδα δεν ολοκληρώθηκε. Θα χρειαστούμε μια φυσική διεύθυνση για να την παραλάβουμε από το χώρο σου. Ακολούθησε το σύνδεσμο “Χρειάζεσαι βοήθεια” για να την καταχωρήσεις"
      ) {
        return "Η επιστροφή του δέματος στη θυρίδα δεν ολοκληρώθηκε. Θα χρειαστούμε μια φυσική διεύθυνση για να την παραλάβουμε από το χώρο σου. Ακολούθησε τα βήματα στο κουμπί chat κάτω δεξιά.";
      } else if (
        failedDeliveryText ===
        "Δεν καταφέραμε να παραδώσουμε το δέμα σου στο Skroutz Point. Θα γίνει νέα προσπάθεια παράδοσης και θα ενημερωθείς με γραπτό μήνυμα. Εναλλακτικά αν επιθυμείς παράδοση σε φυσική διεύθυνση μπορείς να κάνεις αίτημα επαναδρομολόγησης στο σύνδεσμο Χρειάζεσαι βοήθεια"
      ) {
        return "Δεν καταφέραμε να παραδώσουμε το δέμα σου στο Skroutz Point. Θα γίνει νέα προσπάθεια παράδοσης και θα ενημερωθείς με γραπτό μήνυμα. Εναλλακτικά αν επιθυμείς παράδοση σε φυσική διεύθυνση μπορείς να κάνεις αίτημα επαναδρομολόγησης στο κουμπί chat κάτω δεξιά.";
      } else if (
        failedDeliveryText ===
        "Η παραγγελία σου δεν είναι εφικτό να τοποθετηθεί σε θυρίδα. Θα χρειαστούμε μια φυσική διεύθυνση για την παράδοσή της. Ακολούθησε το σύνδεσμο “Χρειάζεσαι βοήθεια” για να την καταχωρήσεις"
      ) {
        return "Η παραγγελία σου δεν είναι εφικτό να τοποθετηθεί σε θυρίδα. Θα χρειαστούμε μια φυσική διεύθυνση για την παράδοσή της. Ακολούθησε τα βήματα στο κουμπί chat κάτω δεξιά.";
      } else {
        return failedDeliveryText;
      }
    },
    handleReturnClick() {
      const route = this.$router.resolve({ name: "returnOrder" }).route;
      route.meta.navigationSource = "return-button";
      this.$router.push({
        name: "returnOrder",
        params: {
          dropoffType: "1",
          barcodeId: this.shipmentTrackingId,
        },
      });
    },
  },
};
</script>

<style>
.status-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 3px;
}

.return-button-container {
  width: 200px;
  position: relative;
}

.return-info {
  position: absolute;
  top: 100%;
}

button {
  flex-shrink: 0;
  display: block;
  padding: 10px 20px;
  border: 1px solid #e8760a;
  border-radius: 4px;
  background-color: #f68b24;
  font-size: 24px;
  color: #fff;
  text-align: center;
  width: 200px;
  &:hover {
    background-color: #e8760a;
  }
}

.shipment-header {
  font-family: "Proxima Nova";
  font-weight: 500;
}
.grid-table p {
  display: grid;
  grid-template-columns: 120px auto;
}
.title-col {
  color: #707070 !important;
}
.tracking-steps li:last-child {
  min-height: unset;
}
.shipment-eta-container {
  display: flex;
}
.shipment-eta-container h2 {
  margin-top: auto;
  margin-bottom: auto;
}
.shipment-eta-container p {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}
.delivery-day {
  padding-left: 5px;
  font-size: 18px;
}
.tracking-steps article > p {
  font-size: 16px;
}
.order-status {
  padding-left: 5px;
  font-size: 18px;
  color: #ffa500;
  display: block;
}

@media screen and (max-width: 733px) {
  .tracking-steps article > p {
    line-height: 22px;
  }
  /* .delivery-day {
    font-size: 12px;
  } */
  .shipment-eta-container {
    flex-direction: column;
  }
  .delivery-day {
    padding-left: 0px;
    padding-top: 5px;
  }
}

@media screen and (min-width: 734px) {
  section {
    padding: 40px !important;
  }
  .shipment-header {
    line-height: 34px;
    font-size: 26px;
  }
  .tracking-steps li article {
    margin-left: 40px !important;
  }
  .shipment-state p + p {
    margin-top: 24px !important;
  }
}

.delay-banner {
  background-color: orange;
  color: white;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
}
</style>
