import * as pjson from "../package.json";

import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import router from "./router";
import App from "./App.vue";

Vue.config.productionTip = false;

Bugsnag.start({
  apiKey: "4ccf40f225abb7d75b17166e8122be50",
  plugins: [new BugsnagPluginVue()],
  appVersion: pjson.version,
});

const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
