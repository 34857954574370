import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/pages/Home.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/xyk",
    name: "xyk",
    // lazy load chunk page
    component: () =>
      import(/* webpackChunkName: "xyk" */ "../components/pages/XYK.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacy" */ "../components/pages/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/privacy_info",
    name: "privacyInfo",
    component: () =>
      import(
        /* webpackChunkName: "privacyInfo" */ "../components/pages/PrivacyPolicyInfo.vue"
      ),
  },
  {
    path: "/verify_employee",
    name: "verifiedEmployee",
    component: () =>
      import(
        /* webpackChunkName: "privacyInfo" */ "../components/pages/VerifiedEmployee.vue"
      ),
  },
  {
    path: "/verify_employee/:barcodeId",
    name: "verifiedEmployeeId",
    component: () =>
      import(
        /* webpackChunkName: "privacyInfo" */ "../components/pages/VerifiedEmployee.vue"
      ),
  },
  {
    path: "/dropoff/2/:barcodeId",
    name: "merchantDropoff",
    component: () =>
      import(
        /* webpackChunkName: "privacyInfo" */ "../components/pages/MerchantDropoff/MerchantDropoff.vue"
      ),
  },
  {
    path: "/dropoff/:dropoffType/:barcodeId",
    name: "returnOrder",
    component: () =>
      import(
        /* webpackChunkName: "privacyInfo" */ "../components/pages/ExternalReturn/ReturnOrder.vue"
      ),
  },
  {
    path: "/merchant/:pickupLocationCode",
    name: "pickupLocationId",
    // lazy load chunk page
    component: () =>
      import(
        /* webpackChunkName: "xyk" */ "../components/pages/MerchantPage.vue"
      ),
  },
  {
    path: "/review/:voucher",
    name: "reviewVoucher",
    component: () => import("../components/pages/ReviewPage.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../components/pages/ContactPage.vue"),
  },
  {
    path: "/cargo/:voucher",
    name: "cargo",
    component: () => import("../components/pages/CargoPickupPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
