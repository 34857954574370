<template>
  <div id="app">
    <meta-manager></meta-manager>
    <router-view></router-view>
  </div>
</template>

<script>
import MetaManager from './components/MetaManager.vue';

export default {
  components: {
    MetaManager,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/fonts.scss';
@import 'assets/styles/tracking.scss';

@media (min-width: 734px) and (max-width: 960px) {
  @import 'assets/styles/tracking_medium.scss';
}
@media (min-width: 961px) {
  @import 'assets/styles/tracking_large.scss';
}

// override styles
@media screen and (min-width: 961px) {
  header img,
  footer img {
    width: 164px !important;
    height: 55px !important;
  }
}
@media screen and (min-width: 734px) and (max-width: 960px) {
  header img,
  footer img {
    width: 119px !important;
    height: 40px !important;
  }
}
@media screen and (max-width: 733px) {
  header img,
  footer img {
    width: 90px !important;
    height: 30px !important;
  }
}
</style>
