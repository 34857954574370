<template>
  <footer>
    <div class="container">
      <div class="top-section footer-top">
        <div class="column footer-img">
          <router-link to="/">
            <img
              src="../../assets/images/footer-logo-skroutz-lm.svg"
              alt="skroutz last mile footer logo"
            />
          </router-link>
        </div>

        <div class="column">
          <i class="icon icon-info"></i>
          <div class="info-container">
            <h3>Χρήσιμες Πληροφορίες</h3>
            <router-link to="/xyk" class="page-link"> Χάρτης Υποχρεώσεων Καταναλωτή </router-link>
            <router-link to="/privacy" class="page-link"> Πολιτική Απορρήτου </router-link>
            <span>
              Γενική Άδεια Ταχυδρομικών Υπηρεσιών,
              <pre>EETT, AM : 20-060</pre>
            </span>
          </div>
        </div>

        <div class="column">
          <i class="icon icon-support"></i>
          <div>
            <h3>Επικοινωνία</h3>
            <router-link to="/contact" class="page-link">Εξυπηρέτηση πελατών</router-link>
          </div>
        </div>

        <div class="column">
          <img class="icon vespa-icon" src="../../assets/images/delivery_dining.svg" />
          <div class="info-container">
            <h3>Δούλεψε για εμάς</h3>
            <a href="https://recruitment.skroutzdelivery.gr/" target="_blank" class="recruitment"
              >Γίνε μέλος μας</a
            >
          </div>
        </div>
      </div>

      <div class="copyright">
        <p>
          <span> &copy; 20[0-9]{2} Skroutz A.E. All Rights and Lefts reserved. </span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style>
.footer-img > a {
  border: unset !important;
}
footer .column .vespa-icon {
  display: none;
}

footer .column .recruitment {
  width: fit-content;
  display: block;
}
.page-link {
  display: block;
  width: fit-content;
}

@media screen and (max-width: 733px) {
  footer .column:nth-last-child(2) {
    margin-top: 24px !important;
  }
  footer .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: max-content;
  }
  footer .info-container .xyk {
    width: fit-content;
  }
}

@media screen and (min-width: 734px) and (max-width: 960px) {
  footer .column {
    width: 224px;
  }
  footer .info-container {
    width: 225px;
  }
}

@media screen and (min-width: 961px) {
  footer .top-section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  footer .column {
    display: flex;
    flex-grow: 1;
    margin-top: 0 !important;
  }
  footer .copyright {
    text-align: unset !important;
  }
}
@media screen and (min-width: 1424px) {
  footer .column .vespa-icon {
    display: block;
    transform: translateX(40px);
  }
}
</style>
