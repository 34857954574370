<template>
  <div>
    <h1 class="search-title">Η αποστολή σου βρίσκεται σε καλά χέρια!</h1>
    <h4>
      Η Skroutz Last Mile διαχειρίζεται τις αποστολές σου με ταχύτητα, διαφάνεια
      και άμεση επικοινωνία
    </h4>
    <form @submit="validateTrackId">
      <p>
        <span class="search-bar-input-wrapper">
          <input
            type="search"
            placeholder="Αναζήτησε τον αριθμό αποστολής"
            :class="!searchTrackId ? 'search-input' : ''"
            v-model="searchTrackId"
          />
        </span>
        <button type="submit" value="submit">
          <img
            src="../../assets/images/search-icon.svg"
            alt="search-icon"
            width="16"
            height="16"
          />
        </button>
      </p>
      <div v-show="isErrorOccured" class="err-msg">
        <svg
          fill="#f68b24"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          ></path>
        </svg>
        <span>Ο κωδικός αυτός δεν βρέθηκε - Προσπαθήστε άλλο κωδικό</span>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SearchPage",
  props: ["isErrorOccured"],
  data() {
    return {
      searchTrackId: "",
    };
  },
  methods: {
    validateTrackId(e) {
      const regex = /\s|#/g; // replace spaces & #
      const newTrackingId = this.searchTrackId.replace(regex, "");

      if (newTrackingId.length > 0) {
        window.location.hash = newTrackingId;
        this.$emit("search-id", newTrackingId);
      }

      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/search_bar.scss";

.err-msg {
  display: flex;
  justify-content: flex-start;
  background: #fac591;
  margin-top: 4px;
  border-radius: 4px;
  padding: 4px;

  svg {
    width: 2em;
    height: 2em;
  }

  span {
    color: #eb2e2b;
    padding: 6px 0;
    margin-left: 10px;
  }
}

.search-title,
h4 {
  width: 490px;
  letter-spacing: 0px;
}
.search-title {
  height: 34px;
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
}
h4 {
  width: 490px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 32px;
  color: rgb(54, 54, 54);
  line-height: 26px;
}

@media (min-width: 961px) {
  h4 {
    height: 51px;
  }
}
@media (max-width: 961px) {
  form {
    max-width: 100%;
  }
}
@media (max-width: 733px) {
  .search-title,
  h4 {
    width: 328px;
  }
  .search-title {
    line-height: 29px;
    height: 46px;
    font-size: 18px;
  }
  h4 {
    line-height: 22px;
    font-size: 14px;
    height: 42px;
    margin-bottom: 24px;
  }
}
</style>
