<template>
  <div :class="!isTrackIdValid ? 'flex-wrapper-app' : ''">
    <!-- <Alert /> -->
    <Navbar />
    <main :class="!isTrackIdValid ? 'search-main' : ''">
      <div class="container">
        <Loader v-if="isLoading" />

        <div v-else>
          <search-page
            v-if="!isTrackIdValid"
            :isErrorOccured="isErrorId"
            :isLoading="isLoading"
            @search-id="requestTracking($event)"
          />
          <tracking-page
            v-else
            :trackingData="requestData"
            :shipmentTrackingId="trackId"
          />
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../pages-sections/Navbar.vue";
import TrackingPage from "../pages-sections/TrackingPage.vue";
import SearchPage from "../pages-sections/SearchPage.vue";
import Footer from "../pages-sections/Footer.vue";
import Loader from "../loader/Loader.vue";
// import Alert from "../pages-sections/EmergencyAlert.vue";

import Bugsnag from "@bugsnag/js";

export default {
  name: "Home",
  components: {
    // Alert,
    Navbar,
    TrackingPage,
    SearchPage,
    Footer,
    Loader,
  },
  data() {
    return {
      requestData: null,
      trackId: "",
      isTrackIdValid: false,
      isErrorId: false,
      isLoading: false,
    };
  },
  watch: {
    $route: "checkUrlHash",
  },
  mounted() {
    this.checkUrlHash();
  },
  methods: {
    checkUrlHash() {
      if (window.location.hash.length > 1) {
        const trackId = window.location.hash.replace("#", "");
        this.requestTracking(trackId);
      } else {
        this.isTrackIdValid = false;
      }
    },
    requestTracking(trackId) {
      this.isLoading = true;

      const regex = /\s|%20/g; // replace spaces & url space %20
      trackId = trackId.replace(regex, "");
      window.location.hash = trackId;
      this.trackId = trackId;

      fetch(
        process.env.VUE_APP_COURIER_API+"/user/hp/" + trackId
        // "https://api.sendx.gr/user/hp/" + trackId
        // "http://localhost:8000/user/hp/" + trackId
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Http error: ${res.status}`);
          }

          return res.json();
        })
        .then((data) => {
          this.isLoading = false;

          if (!(data && this.isResponseDataValid(data)))
            throw new Error(`Response data were not valid - ${data}`);

          this.isTrackIdValid = true;
          this.requestData = data;
        })
        .catch((err) => {
          this.isLoading = false;
          // error send to bugsnag dashboard
          Bugsnag.notify(`Track id: ${this.trackId} - ${err.message}`);
          this.isTrackIdValid = false;
          this.timeoutErrors();
        });
    },
    isResponseDataValid(response) {
      return response.status && response.trackingDetails;
    },
    timeoutErrors() {
      window.location.hash = "";
      this.isErrorId = true;
      setTimeout(() => (this.isErrorId = false), 5000);
    },
  },
};
</script>

<style lang="scss">
.flex-wrapper-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  background-repeat: repeat no-repeat !important;
}
.search-main {
  flex-grow: 1;
  display: flex;
  background: url("../../assets/images/graphic-bg-search-page.svg");
  background-repeat: no-repeat !important;
}

@media screen and (max-width: 733px) {
  .search-main {
    padding: 56px 0 224px;
  }
  main {
    background-position-x: 35% !important;
  }
}
@media screen and (min-width: 733px) and (max-width: 961px) {
  .search-main {
    padding: 136px 0 433px;

    .container {
      margin: 0 59px;
    }
  }
}
@media screen and (max-width: 961px) {
  .search-main {
    background: url("../../assets/images/graphic-bg-search-page.svg") bottom
      center;
    background-size: contain;
    justify-content: center;
    width: 100%;

    .container {
      width: 100%;
    }
  }
}
@media screen and (min-width: 961px) {
  .container {
    max-width: 1320px !important;
  }
  .search-main {
    padding: 145px 0 256px;
    background-position-x: 22rem; //left
    background-position-y: 2.5rem; //top
  }
}
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
}
@media screen and (min-width: 1501px) {
  .search-main {
    background-position-x: 72%; //left
  }
}
</style>
